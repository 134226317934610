.svg-file-view {
  position: relative;
  text-align: center;
}
.svg-file-view:before {
    content: ' '; 
    display: inline-block;
    vertical-align: middle;
    height: 100%;
    font-size: 0;
    line-height: 0;
}
#svg-view {
    max-width: 100%;
    max-height: 100%;
}
