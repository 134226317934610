.permission-editor-explanation {
  user-select: none;
  padding-left: 10px;
  color: #9c9c9c;
  font-size: 12px;
  word-break: keep-all;
  word-wrap: break-word;
  white-space: pre-wrap;
}
.permission-editor .permission-editor__option {
  padding-top: 2px;
  padding-bottom: 2px;
}
.permission-editor .permission-editor__control .permission-editor-explanation {
  display: none;
}

.cur-view-content .permission-editor-select .permission-editor__control,
.cur-view-content .permission-editor-select .permission-editor__control div,
.cur-view-content .permission-editor-select .permission-editor__control .permission-editor__input,
.cur-view-content .permission-editor-select .permission-editor__indicators  {
  height: 1.5rem;
  min-height: 1.5rem;
}

.cur-view-content .permission-editor-select .permission-editor__value-container div:nth-child(2) {
  margin: 0;
  padding: 0;
}

.cur-view-content .permission-editor-select .permission-editor__indicators .permission-editor__indicator {
  padding: 0 0.5rem;
}

.permission-editor__menu .permission-editor__option--is-disabled {
  color: #9c9c9c;
  border-top: 1px solid #dedede;
}

.permission-editor__menu .permission-editor__option--is-disabled:hover {
  background-color: #f0f0f0 !important;
  color: #9c9c9c !important;
}

.permission-editor-btn-add-custom-permission {
  display: flex;
  align-items: center;
  padding: .5rem 0;
  font-size: 12px;
  line-height: 13px;
}

.permission-editor-btn-add-custom-permission .fa {
  font-size: 12px;
  line-height: 13px;
  margin-right: .25rem;
}

.share-dialog-main .permission-editor .permission-editor__placeholder,
.share-dialog-main .true__menu,
.share-dialog-main .true__placeholder,
.permission-editor .btn-add-custom-permission span {
  word-break: keep-all;
}
