.seafile-comment {
  background-color: #f5f5f5;
  display: flex;
  flex-direction: column;
}
.seafile-comment-title {
  border-bottom: 1px solid #e5e5e5;
  min-height: 3em;
  line-height: 3em;
  padding: 0 1em;
  display: flex;
  background-color: #fff;
}
.seafile-comment-title .seafile-comment-title-text {
  width: 100%;
  text-align: center;
  font-weight: 700;
}
.seafile-comment-title .seafile-comment-title-close {
  color: #b9b9b9;
}
.seafile-comment-title .seafile-comment-title-close:hover {
  color: #888;
}
.seafile-comment-item {
  padding: 15px 10px;
  margin-bottom: 0;
}
.seafile-comment-item .seafile-comment-info {
  padding-bottom: 0.5em;
  height: 3em;
  display: flex;
  justify-content: flex-start;
}
.seafile-comment-item .seafile-comment-info .reviewer-info {
  padding-left: 10px;
  max-width: 75%;
}
.seafile-comment-item .seafile-comment-info .review-time {
  font-size: 10px;
  color: #777;
}
.seafile-comment-item .seafile-comment-info .seafile-comment-dropdown {
  margin-left: auto;
}
.seafile-comment-item .seafile-comment-info .seafile-comment-dropdown button {
  border: none;
  box-shadow: none;
  background-color: #fff;
}
.seafile-comment-item .seafile-comment-info .seafile-comment-dropdown .seafile-comment-dropdown-btn {
  color: #999;
  background-color: transparent;
}
.seafile-comment-item .seafile-comment-info .seafile-comment-dropdown:hover .seafile-comment-dropdown-btn {
  color: #555;
}
.seafile-comment-item .seafile-comment-info .seafile-comment-dropdown button:hover,
.seafile-comment-item .seafile-comment-info .seafile-comment-dropdown button:focus {
  border: none;
  box-shadow: none;
  background-color: #eee;
}
.seafile-comment-item .seafile-comment-content {
  margin-left: 42px;
  padding: 5px 10px;
  border-radius: 4px;
  background: #fff;
}
.seafile-comment-item .seafile-comment-content p {
  word-break: break-all;
  margin: 0;
}
.seafile-comment-item .seafile-comment-content ol,
.seafile-comment-item .seafile-comment-content ul,
.seafile-comment-item .seafile-comment-content li {
  margin-left: 10px;
}
.seafile-comment-item .seafile-comment-content table,
.seafile-comment-item .seafile-comment-content th,
.seafile-comment-item .seafile-comment-content td {
  border: 1px solid #333;
}
.seafile-comment-item-resolved {
  background-color: #e6ffed;
}
.seafile-comment-footer {
  padding: 10px;
  border-top: 1px solid #e5e5e5;
  display: flex;
  flex-direction: column;
}
.seafile-comment-footer .add-comment-input,
.seafile-edit-comment .edit-comment-input {
  border: 1px solid #e6e6dd;
  padding: 5px;
  width: 100%;
  min-height: 90px;
  border-radius: 5px;
  background-color: #fff;
}

.seafile-comment-footer .add-comment-input {
  border-bottom: none;
  border-radius: 5px 5px 0 0;
}
.seafile-comment-footer .add-comment-input:focus {
  outline: none;
}
.seafile-comment-footer .comment-submit-container {
  border: 1px solid #e6e6dd;
  border-top: none;
  border-radius: 0 0 5px 5px;
  padding: 5px;
  background: #fff;
  text-align: right;
  position: relative;
}
.seafile-comment-footer .comment-submit-container::before {
  border-top: 1px solid #e6e6dd;
  content: '';
  position: absolute;
  left: 5px;
  right: 5px;
  top: 0;
}
.seafile-comment-footer .submit-comment {
  height: 28px;
}
.seafile-edit-comment .comment-btn {
  height: 28px;
}
