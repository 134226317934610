.search-mask {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.search-container {
  position: relative;
  z-index: 2;
}

.search-icon-left {
  display: flex;
}

.search-icon-right {
  display: flex;
  cursor: pointer;
  pointer-events: all;
  font-style: normal;
  min-width: 25px;
}

.search-icon-arrow {
  right: 25px;
  left: auto;
}

.search-input {
  height: 1.875rem;
  width: 15rem;
}

.search-result-container {
  position: absolute;
  top: 2rem;
  left: 0;
  width: 100%;
  background-color: #fff;
  border-radius: 0 0 3px 3px;
  box-shadow: 0 3px 8px 0 rgba(116, 129, 141, 0.1);
}
.dropdown-search-result-container {
  max-height: 300px;
  overflow: auto;
}

.search-result-container .search-result-none {
  text-align: center;
  line-height: 4rem;
}

.search-result-container .search-result-list {
  margin: 0;
  padding: 0;
  list-style: none;
}

.search-result-list .item-content .item-name {
  color: #eb8205!important;
}

.search-result-container .search-result-item:hover {
  border-left: 2px solid #eb8205;
  background-color: #eee;
}

.search-result-container .search-result-item {
  display: flex;
  padding: 0.25rem 0.5rem;
  border-left: 2px solid #fff;
  font-size: 0.8125rem;
  cursor: pointer;
}

.search-result-item .item-img {
  width: 36px;
  height: 36px;
}
.search-result-item .lib-item-img {
  width: 36px;
  height: 36px;
  padding: 4px;
}
.search-result-item .item-content {
  flex: 1;
  margin-left: 0.25rem;
  overflow-x: hidden;
}
.item-content .item-name a {
  color: #EA8102 !important;
}
.item-content .item-link {
  color: #7d7d7d;
  margin: 0;
}
.item-content .item-text {
  margin: 0;
}
.item-content .item-text b {
  font-weight: bold;
}
.search-result-container .item-content .item-text {
  padding: 0;
  font-size: 0.8125rem;
  line-height: 1.5;
}

.main-panel-south {
  flex: auto;
  overflow: auto;
  height: calc(100% - 50px);
}
.search-page {
  margin: 30px auto;
  width: 65%;
}
.search-page .search-result-container {
  border-radius: 0;
  box-shadow: none;
  padding: 1.25rem 1rem;
}
.search-page .search-page-container {
  padding: 1.25rem 1rem;
  background: #f7f7f8;
}
.search-page .search-page-container .search-input {
  padding-left: 0.5rem;
  width: 30rem;
}
.search-page .search-page-container .fa-angle-double-up,
.search-page .search-page-container .fa-angle-double-down {
  font-size: 1rem;
}
.search-page .advanced-search .search-file-types .search-input {
  padding-left: 0.5rem;
  width: 30rem;
  max-width: 100%;
}
.search-page .search-page-container .search-icon-right {
  left: 28rem;
}
.search-page .paginator {
  text-align: center;
  margin: 1rem 0;
}
.search-page .advanced-search, .search-page .search-filters {
  color: #747474;
}
.search-page .search-filters {
  padding-top: 10px;
}
.search-page .advanced-search .search-repo, 
.search-page .advanced-search .search-file-types {
  padding: 5px 0;
}
.search-file-types .search-file-types-form {
  top: 10px;
}
.search-page .advanced-search .search-catalog {
  border-top: 1px dashed #e2e2e2;
  padding: 10px 0;
}
.search-page .advanced-search .search-catalog:first-child {
  border: none;
}
.search-page .custom-checkbox .custom-control-input:checked ~ .custom-control-label::before {
  background-color: #3B88FD;
}
.search-date .ant-input {
  height: 2.375rem;
  padding: 0.375rem 0.75rem;
  line-height: 1.6;
  border: 1px solid rgba(0, 40, 100, 0.12);
  border-radius: 3px;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.search-date .select-data-icon {
  position: absolute;
  right: 1.5rem;
  top: 0.5rem;
  color: #b2b2b2;
}
.search-page .search-result-container .search-result-item {
  padding: 0;
  margin-bottom: 1rem;
}
.search-page .search-result-container .search-result-item:hover {
  border-left: 2px solid #fff;
  background-color: inherit;
}
.search-page .search-result-item .item-content {
  margin-left: 0.8rem;
}
.search-page .search-result-item .item-content .item-link a {
  color: #8b8b8b !important;
}
.search-page .search-result-container .search-result-item {
  cursor: default;
}
.rc-calendar tbody tr {
  height: 1.75rem;
}

@media (max-width: 767px) {
  .common-toolbar .search {
    margin: 0;
  }
  
  .search-icon-container {
    display: flex;
    width: 2rem;
    align-items: center;
    justify-content: center;
  }

  .search-icon {
    font-size: 1.25rem;
    line-height: 1;
    color: #999;
  }

  .search-container {
    position: fixed;
    z-index: 2;
    top: 5rem;
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;
  }

  .search-input {
    height: 2.5rem;
    width: 20rem !important;
    box-shadow: 5px 5px 5px #888888;
  }

  .search-result-container {
    position: absolute;
    top: 2.5rem;
    left: auto;
    width: 20rem;
  }

  .search-page {
    margin: 0;
    width: 100%;
    height: 100%;
  }

  .search-page .search-page-container .search-input {
    box-shadow: none;
    width: 95% !important;
  }

  .search-page .search-page-container .search-icon-right {
    left: 85%;
  }

  .search-page .search-result-container {
    top: 0;
    left: 0;
    width: 100%;
  }
}
