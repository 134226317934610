.text-file-view {
    overflow: auto;
}
.text-file-view .ReactCodeMirror {
    box-shadow: 0 0 6px #ccc;
    border: 1px solid #ccc;
    width: calc(100% - 40px);
    max-width: 950px;
    margin: 0 auto;
}
.text-file-view .CodeMirror {
    height: auto;
    min-height: 300px;
}
.text-file-view .CodeMirror-scroll {
    min-height: 300px;
}
