.file-internal-link {
  font-size: .8em;
  color: #808080;
  cursor: pointer;
  margin-left: .5rem;
  vertical-align: text-bottom;
}
.file-internal-link:hover {
  color: #333;
}
