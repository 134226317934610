.file-chooser-container {
  padding: 0.5rem;
  height: 20rem;
  border: 1px solid rgba(0, 40, 100, 0.12);
  border-radius: 3px;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  overflow: auto;
  font-size: 1rem;
}

.item-toggle{
  position: absolute;
  height: 1.5rem;
  width: 1.5rem;
  left: 0;
  top: 0;
  line-height: 1.5rem !important;
  text-align: center;
  cursor: pointer;
  color: #c0c0c0;
}

.file-chooser-container .list-view {
  margin-top: 0.25rem;
}

.list-view-header {
  position: relative;
  padding-left: 1.5rem;
}
.list-view-header:hover {
  background-color: #FDEFB9;
}

.list-view-header .name {
  color: #eb8205;
}

.list-view-content {
  margin: 0;
  padding: 0;
  list-style: none;
}

.file-chooser-item {
  position: relative;
  padding-left: 22px;
}

.file-chooser-item .item-info {
  height: 1.5rem;
  cursor: pointer;
  position: relative;
  line-height: 1.625;
}

.file-chooser-item .item-active {
  background: #F3AF7D !important;
  border-radius: 2px;
  box-shadow: inset 0 0 1px #999;
  color: #fff;
}

 .file-chooser-item .item-info:hover {
  background: #FDEFB9;
  border-radius: 2px;
  box-shadow: inset 0 0 1px #999;
}

.file-chooser-item .item-info .name {
  flex: 1;
}

.file-chooser-item .item-active .icon {
  color: #fff !important;
}

.file-chooser-search-input {
  position: relative;
}

.file-chooser-search-input .search-control {
  position: absolute;
  top: 0.5rem;
  right: 0.7rem;
}

.file-chooser-search-input .search-input {
  width: 100%;
}

.file-chooser-search-container {
  height: 20rem;
  position: relative;
  border: 1px solid #eee;
  padding: 10px;
  overflow: auto;
}

.file-chooser-search-close {
  position: absolute;
  right: -0.5rem;
  top: -0.5rem;
}

.searched-active {
  background: #F3AF7D !important;
  border-radius: 2px;
  box-shadow: inset 0 0 1px #999;
}

.searched-active td {
  color: #fff;
}

.searched-active .icon {
  color: #fff !important;
}

.select-open-repo {
  background: #FDEFB9;
}

.file-chooser-table td {
  border-bottom: 1px solid rgba(0, 0, 0, 0);
}

.file-chooser-item .item-info .item-text {
  padding-left: 2.8rem;
  font-size: 15px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 24px;
}

.file-chooser-item .item-info .item-left-icon {
  position: absolute;
  display: flex;
  align-items: center;
  top: 0;
  left: 0;
  padding-left: 1.5rem;
}




