.file-uploader-container {
  display: flex;
  /* flex: 1; */
}

.file-uploader {
  position: fixed;
  bottom: 99999px;
}

.uploader-list-view {
  display: flex;
  flex-direction: column;
  position: fixed;
  right: 1px;
  bottom: 1px;
  width: 35rem;
  height: 20rem;
  border: 1px solid #ddd;
  border-radius: 3px;
  box-shadow: 0 0 6px #ddd;
  background-color: #fff;
  z-index: 1050;
}

.uploader-list-header {
  background-color: #f0f0f0;
  padding: 0.375rem 0.625rem;
  font-size: 1rem;
  line-height: 1.5;
  color: #322;
  display: flex;
  justify-content: space-between;
  min-height: 2.25rem;
}

.uploader-list-header .uploader-options span{
  display: inline-block;
  margin-left: 0.25rem;
  font-size: 18px;
  color: #b8b8b8;
  cursor: pointer;
}

.uploader-list-content {
  padding: 0rem 1rem 1.25rem;
  background-color: #fff;
  overflow: auto;
}

.file-upload-item {
  height: 44px;
}

.upload-progress .progress-container {
  height: 24px;
  padding: 4px 0;
}

.upload-progress .progress {
  height: 5px;
  width: 80%;
}

.upload-progress .progress .progress-bar {
  color: #e83;
}

.upload-progress .progress-text {
  margin-top: 2px;
  font-size: 12px;
  line-height: 12px;
  color: #666666;
}

.upload-operation .saving {
  color: #ee8204;
  word-wrap: break-word;
}

.disabled-link {
  color: #999999;
}